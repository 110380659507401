.nav {
    position: fixed;
    top: 0;
    padding: 20px;
    width: 100%;
    height: 30px;
    z-index: 1;

    /* Animations */
    transition-timing-function: ease-in;
    transition: all 0.5s;
}

.nav_contents {
    display: flex;
    justify-content: space-between;
}

.nav_black {
    background-color: #0f0f0f;
}

.nav_logo {
    position: fixed;
    top:20px;
    left: 0px ;
    cursor: pointer;
    width: 100px;
    object-fit: contain;
    padding-left: 20px;
}

.nav_avatar {
    position: fixed;
    right: 20px;
    width: 40px;
    cursor: pointer;
}