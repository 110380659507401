.row {
    color: white;
    /* margin-left: 20px; */
    background-color: #111;

}

.row_title {
    margin-left: 20px;
}

.row_posters {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 20px;
}

.row_posters::-webkit-scrollbar {
    display: none;
}

.row_poster {
    max-height: 100px;
    object-fit: contain;
    margin-right: 10px;
    width: 100%;
    transition: transform 450ms;
}

.row_poster:hover {
    transform: scale(1.08);
    opacity: 1;
}

.row_posterLarge {
    max-height: 250px;
}